<template>
  <div>
    <!-- <TopCarousel /> -->
    <img class="w-full banner-top" src="@/assets/images/otherParts/banner.png" />
    <ContentArea>
      <div class="radio-container">
        <div class="radio-group">
          <div class="radio-btn" v-for="item of cateList" :key="item.categoryId" :value="item.categoryId" :class="curCateId1==item.categoryId?'active':''">
            <div v-if="item.type == 0||item.type == 1" @click="getProductsByCateId(item.categoryId)">
              {{ item.categoryName }}
            </div>
            <a-dropdown :trigger="['click']" v-else>
              <div>{{ item.categoryName }}</div>
              <a-menu slot="overlay" class="menus" v-if="item.children && item.children.length > 0">
                <a-menu-item v-for="child of item.children" :key="child.categoryId" @click="getProductsByCateId(item.categoryId, child.categoryId)">
                  {{ child.categoryName }}
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </div>
        </div>
      </div>
      <PrevNext :current-num="current" :total-num="totalPage" @change="onPageNumChange" class="parts-container">
        <div class="parts-item" v-for="(item,idx) of dataList" :key="idx" @click="go2chisel(item)" :style="{ cursor: curCateType == 0 ? 'pointer' : '' }">
          <div class="image-container">
            <img :src="fileBaseUrl + item.image" />
          </div>
          <div class="image-desc">
            {{ item.title }}
          </div>
        </div>
      </PrevNext>
      <a-pagination v-model="current" :page-size="pageSize" :total="total" @change="onPageNumChange" :item-render="itemRender" class="flex justify-center" />
    </ContentArea>
  </div>
</template>

<script>
import deviceMixin from '@/store/device-mixin'
export default {
  name: 'OtherParts',
  mixins: [deviceMixin],
  data () {
    return {
      cateList: [],
      curCateId1: '',
      current: 1,
      total: 0,
      totalPage: 0,
      fileBaseUrl: this.$baseUrl,
      dataList: []
    }
  },
  computed: {
    pageSize () {
      return this.isMobile ? 3 : 8
    },
    curCateType () {
      return this.cateList.find(c => c.categoryId == this.curCateId1)?.type
    }
  },
  created () {
    this.$common.categoryList().then(res => {
      this.cateList = res.data
      this.getProductsByCateId(this.cateList[0].categoryId)
    })
  },
  methods: {
    itemRender (current, type, originalElement) {
      if (!this.isMobile) {
        if (type === 'prev') {
          return ''
        } else if (type === 'next') {
          return ''
        }
      }
      return originalElement
    },
    onPageNumChange (current) {
      this.current = current
      this.getProductsByCateId(this.curCateId1, this.curCateId2, true)
    },
    getProducts (cateId, fn) {
      this.$otherParts[fn](cateId, this.current, this.pageSize).then(res => {
        if (!res.rows || res.rows.length === 0) {
          this.$message.warn(this.$t('common.noData'))
          return
        }
        const dataT = res.rows
        dataT.forEach(d => {
          if (d.bizJzhMallExtend) {
            d.image = JSON.parse(d.bizJzhMallExtend.productImage)[0].url
          }
        })
        this.dataList = dataT
        this.total = res.total
        this.totalPage = Math.ceil(this.total / this.pageSize)
      })
    },
    getProductsByCateId (cate1, cate2, isPage) {
      if (!isPage) {
        this.current = 1
      }
      this.curCateId1 = cate1
      this.curCateId2 = cate2

      if (this.curCateType == 0) {
        this.getProducts(cate1, 'mallListPage')
      } else if (this.curCateType == 1) {
        this.getProducts(cate1, 'partListPage')
      } else {
        this.getProducts(cate2, 'partListPage')
      }
    },
    go2chisel (item) {
      if (this.curCateType == 0) {
        this.$router.push({ name: 'ChiselDetail', query: { id: item.mallId } })
      }
    }
  }
}
</script>
<style lang="less" scoped>
.radio-container {
  margin: 20px 0;
  display: flex;
  justify-content: center;

  .radio-group {
    display: flex;
    flex-wrap: wrap;

    .radio-btn {
      border: 1px solid #d9d9d9;
      padding: 4px 15px;
      cursor: pointer;

      &.active {
        background: #D7E2D3;
      }
    }
  }

  /deep/ .ant-dropdown-trigger {
    max-width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.menus {
  max-height: 300px;
  overflow-y: scroll;
}

.parts-container {
  display: flex;
  flex-wrap: wrap;
}

.parts-item {
  margin-bottom: 20px;

  .image-container {
    background: #E8E8E8;
    display: flex;
    justify-content: center;

    img {
      height: 180px;
    }
  }
  .image-container:hover{
    transform: scale(1.1);
  }

  .image-desc {
    height: 34px;
    background: #F7F7F7;
    font-size: 10px;
    font-weight: 400;
    color: #333333;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (min-width:1024px) {
  .parts-container {
    justify-content: flex-start;
  }

  .parts-item {
    width: calc((60vw - 20px * 3) / 4);
    margin-right: 20px;

    &:nth-child(4n) {
      margin-right: 0;
    }
  }
}

@media screen and (max-width:1000px) {
  .parts-item {
    width: 90vw;
  }
}
</style>
